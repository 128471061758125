#travelList{
    justify-content: space-evenly;
    display: flex;
    gap: 1rem;
}

.travelItem{
    min-width: 30vh;
    max-width: 50vh;
    display: block;
}

.travelIcon{
    font-size: 4rem;
    width: 100%;
}

.travelTitle{
    border-bottom: dotted;
    width: 100%;
}

.travelText{
    width: 100%;
}

@media (max-width: 600px){
    #travelList{
        display: grid;
        gap: 1rem;
    }
}