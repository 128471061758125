:root {
  --primary: #F0D9AF;
  --second: #F6822C;
  --background: #f2f2f2;
}

.App {
  text-align: center;
  font-family: 'Hanalei Fill', cursive;
  background-color: var(--background);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/** LangindPage **/
h2{
  text-transform: uppercase;
}

/* Home */
#home{
  padding-top: 75px;
  height: 50vh;
  background-color: #282c34;
}

#home h2{
  background-color: #282c34;
}

/* Product */
#product{
  padding: 3rem 1rem 2.5rem 1rem;
  text-align: center;
  color: white;
}

#product h2{
  padding-bottom: 2rem;
  color:#000000;
  font-family: 'Hanalei Fill', cursive;
}

#productList{
  justify-content: space-evenly;
  display: flex;
  gap: 1rem;
}
   
/* rrss */
#travel{
  height: 50vh;
  background-color: #282c34;
  padding: 5rem 1rem 7rem 1rem;
  overflow:hidden;
  position:relative;
  color: var(--primary);
}

#travel::before{ 
  content:'';
  font-family:'shape divider from ShapeDividers.com';
  position: absolute;
  z-index: 3;
  pointer-events: none;
  background-repeat: no-repeat;
  bottom: -0.1vw;
  left: -0.1vw;
  right: -0.1vw;
  top: -0.1vw; 
  background-size: 100% 62px;
  background-position: 50% 0%;  background-image: url('data:image/svg+xml;charset=utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.28 2.17" preserveAspectRatio="none"><path d="M0 .5c3.07.55 9.27-.42 16.14 0 6.88.4 13.75.57 19.14-.11V0H0z" fill="%23f2f2f2"/><path d="M0 1c3.17.8 7.29-.38 10.04-.55 2.75-.17 9.25 1.47 12.67 1.3 3.43-.17 4.65-.84 7.05-.87 2.4-.02 5.52.88 5.52.88V0H0z" opacity=".5" fill="%23f2f2f2"/><path d="M0 1.85c2.56-.83 7.68-.3 11.79-.42 4.1-.12 6.86-.61 9.58-.28 2.73.33 5.61 1.17 8.61 1 3-.19 4.73-.82 5.3-.84V.1H0z" opacity=".5" fill="%23f2f2f2"/></svg>'); 
}
  
/* contact */
#contact{
  padding: 7rem 1rem 2rem 1rem;
  overflow:hidden;
  position:relative;
}

#contact::before{ 
  content:'';
  font-family:'shape divider from ShapeDividers.com';
  position: absolute;
  z-index: 3;
  pointer-events: none;
  background-repeat: no-repeat;
  bottom: -0.1vw;
  left: -0.1vw;
  right: -0.1vw;
  top: -0.1vw; 
  background-size: 100% 90px;
  background-position: 50% 0%;  background-image: url('data:image/svg+xml;charset=utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.28 2.17" preserveAspectRatio="none"><path d="M0 .5c3.07.55 9.27-.42 16.14 0 6.88.4 13.75.57 19.14-.11V0H0z" fill="%23282c34"/><path d="M0 1c3.17.8 7.29-.38 10.04-.55 2.75-.17 9.25 1.47 12.67 1.3 3.43-.17 4.65-.84 7.05-.87 2.4-.02 5.52.88 5.52.88V0H0z" opacity=".5" fill="%23282c34"/><path d="M0 1.85c2.56-.83 7.68-.3 11.79-.42 4.1-.12 6.86-.61 9.58-.28 2.73.33 5.61 1.17 8.61 1 3-.19 4.73-.82 5.3-.84V.1H0z" opacity=".5" fill="%23282c34"/></svg>'); 
}
  
@media (min-width:2100px){
  #contact::before{
  background-size: 100% calc(2vw + 90px);
  }
}

@media (max-width: 600px){
  #home{
    padding-top: 0px;
  }
  #productList{
    display: grid;
  }

  #travel{
    height: 100%;
  }
}

@media (min-height: 700px){
  #travel{
    height: 30vh;
  }
}

@media (max-height: 500px){
  #travel{
    height: 100%;
  }
}

@media (min-width:2100px){
  #travel::before{
    background-size: 100% calc(2vw + 62px);
  }
}