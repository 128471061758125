#footer{
  background-color: #030304;
  color: antiquewhite;
  padding: 1rem;
}

#logoFooter{
  max-width: 30vh;
  margin-bottom: 2%
}

#rrssFooter a{
  color: inherit;
  text-decoration: none;
  font-size: 2rem;
  gap: 1rem;
}