.cardDiv{
  min-width: 30vh;
  max-width: 70vh;
  display: flex;
}

.contentCard{
  padding: 5px 8px 0px 8px !important;
  transform: translateY(-30px);
}

.imageDiv{
  background-color: #030304;
  border-radius: 10px;
}

.imageDiv:hover{
  background-color: #2c2c3c;
  transform: translateY(-3px);
  box-shadow: 0 15px 10px #0000001a;
}

.textCard{
  background: #ffffff;
  padding: 10px 0px;
  border-radius: 10px;
  position: relative;
  z-index: 1;
  margin-left: 25px;
  margin-right: 25px;
  align-items: center;
  display: flexbox;
  gap: 2rem;
}

.textCard span{
  color:#000000;
  font-weight: 600;
  font-size: 1rem;
}

.modalBox{
  border: 3px solid var(--primary) !important;
  padding-bottom: 0 !important;
  overflow-y: scroll;
  max-height: 80vh;
  /* height:'100%'; */
  
}

.textModal{
  text-align: left;
  color:#848484;
}

.valueModal{
  text-align: justify;
  color:#000000;
}

.imageModal{
  min-height: 30vh;
  max-height: 60vh;
  margin-bottom: 1rem;
}

.pointModal{
  width: 20% !important;
  float: right;
}

.closeModal{
  float: right;
  padding: 0 !important;
}

.closeModal:hover{
  color: var(--second);
}

.graphModal{
  position: relative;
  float: right;
}

@media (max-width: 600px){
  .cardDiv{
    
  justify-content: space-evenly;
  }
}