#contactDiv{
  display: flex;
  justify-content: space-evenly;
  margin: 3rem 0 3rem 0;
}

#contactForm{
  display: grid;
  width: 60vh;
}

#contactForm button{
  background-color: var(--second);
  color: #000;
}

#contactForm button:hover{
  background-color: var(--primary);
}

#contactImg{
  margin: 2rem;
  max-width: 40vw;
}

#contactImg img{
  height: 80%;
}

@media (max-width: 600px){
  #contactDiv{
    display: grid;
  }

  #contactImg{
    margin: 0 0 1rem 0;
    justify-self: center;
    height: 200px;
  }

  #contactImg img{
    position: static;
    height: 80%;
  }

  #contactForm{
    display: grid;
    gap: 1rem;
    width: 100%;
  }
}