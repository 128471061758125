.nav{
  background: linear-gradient(90deg,rgb(110,94,254) 0%, rgb(36, 12, 247) 100%);
  background: linear-gradient(90deg,rgb(3, 3, 3) 0%, rgb(28, 24, 62) 100%);
  color: antiquewhite;
  display: flex;
  position: fixed;
  width: 100%;
  justify-content: center;
  align-items: stretch;
  padding: 0 1rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  z-index: 4;
}

.navbar-logo{
  justify-self: start;
  cursor: pointer;
  width: 10vh;
  top: 10;
}

.navbar-menu{
  list-style: none;
  padding: 0 20px;
  position: relative;
  margin: 0;
  display: flex;
  gap: 1rem;
}

.navbar-menu a{
  color: #F0D9AF;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  padding: .25rem;
  font-size: 1.2rem;
  font-weight: 600;
}

.navbar-menu a:hover{
  color: #F6822C;
}

.navbar-menu li a:hover::after {
  content: "";
  width: 1.3rem;
  height: 2px;
  background: #F6822C;
  position: absolute;
  bottom: 15px;
}

.navbar-menu a svg{
  margin: 1%;
}

.navbar-icon{
  display: none;
}

@media (max-width: 600px){
  .nav{
    /* position: relative; */
    position: fixed;
  }

  .navbar-icon{
    display: block;
    /* position: absolute; */
    position: fixed;
    align-items: center;
    cursor: pointer;
    top: 0;
    right: 0;
    transform: translate(-100%, 80%);
    font-size: 1rem;
  }

  .navbar-menu{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: fixed;
    top: 60px;
    width: 200px;
    right: -300px;
    height: 80vh;
    background-color: #F0D9AF;
    padding: 20px 0 0 3px;
    transition: all 0.5s ease;
  }

  .navbar-menu a{
    color: #F0D9AF;
  }

  .navbar-menu.active
  {
    right: 0px;
  }

  .navbar-menu.active a{
    color: #000;
  }

  .navbar-menu.active a:hover{
    color: #F6822C;
    font-size: 1.2rem;
  }
}